import './App.css';
import React from 'react';
import AppView from './pages/AppView';

//React App ~ Main Page Loader.
function App() 
{
  return (
    <div>
      <AppView />
    </div>
  );
}

export default App;
