import React from 'react';
import styles from "../styles.module.css";
/*
Starts off by asking for fence-type, the first question; hence default. 
*/
const defaultLayout = ({passData, fenceCount, returnClick}) => {

  return (
    <div style={{paddingBottom: "80px", paddingTop: '30px'}}>
        <div id="fenceTypes">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 style={{color: 'maroon'}}>Select Fence Type</h1>
            <div style={{ textAlign: "center", display: 'flex', justifyContent: 'flex-start' }}>
                <button className={styles.Button} onClick={() => returnClick("fenceType")} style={{ display: fenceCount === 0 ? 'none' : 'block' }}>Return</button>
            </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
          <div style={{textAlign: 'center'}} onClick={() => passData("Aluminum")}>
              <h2>Aluminum</h2>
              <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Liberty-Aluminum-Fence-Cover-Photo.webp"} alt="Liberty-Aluminum-Fence-Cover" />
          </div>

          <div style={{textAlign: 'center'}} onClick={() => passData("Cedar Privacy")}>
              <h2>Cedar Privacy</h2>
              <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Solid-Vertical-Privacy-Fence-Cover-Photo.webp"} alt="Solid-Vertical-Privacy-Fence-Cover" />
          </div>

          <div style={{textAlign: 'center'}} onClick={() => passData("Vinyl Privacy")}>
              <h2>Vinyl Privacy</h2>
              <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Open-Picket-Topper-Privacy-Fence-Cover-Photo.webp"} alt="Open-Picket-Topper-Privacy-Fence-Cover" />
          </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
          <div style={{textAlign: 'center'}} onClick={() => passData("Chainlink")}>
              <h2>Chainlink</h2>
              <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Chainlink-Cover-Photo.webp"} alt="Chainlink-Cover" />
          </div>
          <div style={{textAlign: 'center'}} onClick={() => passData("Cedar Picket")}>
              <h2>Cedar Picket</h2>
              <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Victorian-Picket-Fence.webp"} alt="Cedar-Victorian-Picket-Fence" />
          </div>
          <div style={{textAlign: 'center'}} onClick={() => passData("Vinyl Picket")}>
              <h2>Vinyl Picket</h2>
              <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Vinyl-Victorian-Picket-Fence.webp"} alt="Vinyl-Victorian-Picket-Fence" />
          </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
          <div style={{textAlign: 'center'}} onClick={() => passData("Deer")}>
              <h2>Deer</h2>
              <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Deer-Fence-Cover-Photo.webp"} alt="Deer-Fence-Cover" />
          </div>
          <div style={{textAlign: 'center'}} onClick={() => passData("Wood Post & Rail")}>
              <h2>Wood Post & Rail</h2>
              <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Post-and-Rail-Cover-Photo.webp"} alt="Post-and-Rail-Cover" />
          </div>
          <div style={{textAlign: 'center'}} onClick={() => passData("Vinyl Post & Rail")}>
              <h2>Vinyl Post & Rail</h2>
              <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Vinyl-Diamond-Rail-Cover-Photo.webp"} alt="Vinyl-Diamond-Rail-Cover" />
          </div>
        </div>

      </div>
    </div>
  );
}

export default defaultLayout;
