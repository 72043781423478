import React, { useState, useMemo, useEffect, useRef } from 'react';
import { GoogleMap, DrawingManagerF } from '@react-google-maps/api';
import html2canvas from 'html2canvas';
import CustomFenceComponent from '../components/CustomFenceComponent';
import MobileFenceComponent from '../components/MobileFenceComponent';
import simplify from 'simplify-js';
import FenceSelect from './fenceSelect/fenceSelect';
import styles from "./styles.module.css";
/*Google Map Overlay Icons*/
import whiteSquare  from "../images/circle.png"//For Map Markers Icon
import whiteDrawing from '../images/whiteDrawing.png';
import whiteCompass from '../images/whiteCompass.png';
import whiteGate    from '../images/whiteGate.png';
import blackDrawing from '../images/blackDrawing.png';
import blackCompass from '../images/blackCompass.png';
import brownGate    from '../images/brownGate.png';

const MapPage  = ({ passData, passGeoLat, passGeoLng, isMobile}) =>  {

    const center = useMemo(() => ({ lat: passGeoLat, lng: passGeoLng}), []);//Map Cordinates
    //const center = useMemo(() => ({ lat: 41.28941708426977, lng: -73.42966293068723 }), []);

    const [selectedFence, setSelectedFence] = useState(1);//By default we'll have no drawing tool.
     
    //Line Stroke Colors) Royal Blue, Gold, Cyan, Tomato, Violet-Blue, Lime, Orange-Red, Pink, Violet.
    const strokeColors = ['#4169E1', '#CCAC00', '#3457C4', '#FF6347', '#8A2BE2', '#32CD32', '#FF4500', '#FF69B4', '#228B22', '#9400D3',];
        
    //Used for submit button animation. 
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    //Used to store original screen height ~ Used in creating image width. 
    const [originalH, setOriginalH] = useState();

    /*Used to capture drawing*/
    const captureAndCreateImage = () => 
    {
        setIsLoading(true);//Triggering button animation
        const targetElement = document.getElementById('tg');//Entire Div of return
        const totalRenderedHeight = targetElement.scrollHeight + 100;//Added height to ensure screen fit for picture
        const totalRenderedWidth = (targetElement.scrollHeight / originalH) * targetElement.scrollWidth; //Revised width to keep aspect ratio of above

        //Modifying the displayed element prior to triggering the capture ~ ALTERED 08/04/2024. 
        
        //targetElement.style.height = totalRenderedHeight + 'px';
        //targetElement.style.width = totalRenderedWidth + 'px';
        setTimeout(function()
        {
            html2canvas(targetElement, {useCORS: true}) 
            .then((canvas) => {
                const imageDataUrl = canvas.toDataURL();//Converting the clientView to image. 
                setTimeout(function()//Timeout is used for button animation. 
                {
                    setIsLoading(false);//Finished loading, update to success.
                    setIsSuccess(true);
                    setTimeout(function()
                    {
                        passData(imageDataUrl);//Pass image url to next step. 
                    }, 1200);
                }, 800);
            })
            .catch((error) => {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true);
                console.error('Error capturing HTML:', error);
            });
        }, 800);
        
    };

    //Setting up arrays to house line length & polyline objects
    const blankLinesArray = Array.from({ length: 10 }, () => Array.from({ length: 10 }, () => 0));
    const blankGatesArray = Array.from({ length: 10 }, () => Array.from({ length: 10 }, () => 0));
    const blankPolyArray = Array.from({ length: 10 }, () => Array.from({ length: 10 }, () => 0));
    const blankMarkerArray = Array.from({ length: 10 }, () => Array.from({ length: 10 }, () => 0));
    const blankMarkerArray2 = Array.from({ length: 10 }, () => Array.from({ length: 10 }, () => 0));
    const [linesArray, setLinesArray] = useState(blankLinesArray);   //Houses Line Lengths
    const [gatesArray, setGatesArray] = useState(blankGatesArray);   //Houses Line Lengths
    const [polyArray, setPolyArray] = useState(blankPolyArray);      //Houses Polyline Objects
    const [markerArray, setMarkerArray] = useState(blankMarkerArray);//Houses Marker Objects Poly
    const [markerArray2, setMarkerArray2] = useState(blankMarkerArray2);//Houses Marker Objects Gate

    //House Drawing Mode & Objects Related.
    const [clickDrawn, setClickDrawn] = useState(false);//Alerts of Drawing Type; click vs drag.
    const [map, setMap] = useState(null);// Stores Map Object.
    const [mapDrag, setMapDrag] = useState(true);// Toggle Map Draggability
    const [drawingMode, setDrawingMode] = useState(false);// Toggle Drawing Mode
    const [drawingManager, setDrawingManager] = useState(null);// Stores Drawing Object.

    const [empireSiteView, setEmpireSiteView] = useState('inline');//Controls empire-site view. 
    const [appView, setAppView] = useState('none');//Controls app-view

    // Ref to force effect update in listener events.
    const [gateSelected, setGateSelected] = useState(false);
    const [gateSelectView, setGateSelectView] = useState('none');
    const [gateType, setGateType] = useState('S');
    const gateSelectedRef = useRef(gateSelected);
    gateSelectedRef.current = gateSelected;
    const gateTypeRef = useRef(gateType);
    gateTypeRef.current = gateType;

    //Used to house onclick listener events.
    let mapAreaClickListener;
    let mouseUpListener;
    let mouseDownListener;
    let mouseMoveListener;
    let touchEndListener;
    let mouseUpCounter = 0;//Used to store mouseUp counts.

    const addGate = (clickedLocation, gType) =>
    {
        const firstOpenSpaceIndex = gatesArray[selectedFence].indexOf(0);//Adding gate to first spot in array
        const marker = new window.google.maps.Marker
        ({
            position: clickedLocation,
            map: map,
            icon: whiteSquare,
            label: selectedFence + gType + (firstOpenSpaceIndex + 1),
            zIndex: 1,
        });
        const updatedArray4 = [...gatesArray];
        const updatedArray5 = [...markerArray2];
        updatedArray4[selectedFence][firstOpenSpaceIndex] = gType;
        updatedArray5[selectedFence][firstOpenSpaceIndex] = marker;
        setGatesArray(updatedArray4);
        setMarkerArray2(updatedArray5);
    }

    const hideGateSelect = (gateType) =>
    {
        setGateType(gateType);
        setAppView('flex');
        setGateSelectView('none');
    }


const [mapControlsInitialized, setMapControlsInitialized] = useState(true);
    function setMapC(map)
    {

        if(mapControlsInitialized)
        {

        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.flexDirection = 'column';
        container.style.alignItems = 'center';
        container.style.border = '2px solid black';
        if(isMobile)
        {
            container.style.width = '30px';
            container.style.marginRight = '16px';
            container.style.marginTop = '6px';
            container.style.border = '0px solid black';
        }
        

        const div1 = document.createElement('div');
        div1.id="c1"
        div1.style.border = '2px solid black';
        div1.style.backgroundColor = '#620d0e';
        div1.style.color = 'white';
        div1.style.width = '80px';
        if(isMobile)
        {
            div1.style.width = '40px';
        }
        div1.style.textAlign = 'center';
        div1.addEventListener('click', () => clickHandler(1));

        const b1 = document.createElement('b');
        b1.style.fontSize = '16px';
        if(isMobile)
        {
            b1.appendChild(document.createTextNode('Tour'));
        }else{
            b1.appendChild(document.createTextNode('Touring'));
        }

        const img1 = document.createElement('img');
        img1.id = "c1img";
        if(isMobile)
        {
            img1.style.width = '35px';
            img1.style.height = '35px';
        }else{
            img1.style.width = '50px';
            img1.style.height = '50px';
        }
        img1.src = whiteCompass;
        img1.alt = 'Drawing Image';

        const div2 = document.createElement('div');
        div2.id="c2"
        div2.style.border = '2px solid black';
        div2.style.backgroundColor = 'white';
        div2.style.color = 'black';
        div2.style.width = '80px';
        if(isMobile)
        {
            div2.style.width = '40px';
            div2.style.marginTop = '7px';
        }
        div2.style.textAlign = 'center';
        div2.addEventListener('click', () => clickHandler(2));

        const b2 = document.createElement('b');
        b2.style.fontSize = '16px';
        if(isMobile)
        {
            b2.appendChild(document.createTextNode('Draw'));
        }else{
            b2.appendChild(document.createTextNode('Drawing'));
        }

        const img2 = document.createElement('img');
        img2.id = "c2img";
        if(isMobile)
        {
            img2.style.width = '35px';
            img2.style.height = '35px';
        }else{
            img2.style.width = '50px';
            img2.style.height = '50px';
        }
        img2.src = blackDrawing;
        img2.alt = 'Drawing Image';

        const div3 = document.createElement('div');
        div3.id="c3"
        div3.style.border = '2px solid black';
        div3.style.backgroundColor = 'white';
        div3.style.color = 'black';
        div3.style.width = '80px';
        if(isMobile)
        {
            div3.style.width = '40px';
            div3.style.marginTop = '7px';
        }
        div3.style.textAlign = 'center';
        div3.addEventListener('click', () => clickHandler(3));

        const b3 = document.createElement('b');
        b3.style.fontSize = '16px';
        b3.appendChild(document.createTextNode('Gate'));

        const img3 = document.createElement('img');
        img3.id = "c3img";
        if(isMobile)
        {
            img3.style.width = '35px';
            img3.style.height = '35px';
        }else{
            img3.style.width = '50px';
            img3.style.height = '50px';
        }
        img3.src = brownGate;
        img3.alt = 'Drawing Image';

        const logoText = document.createElement('h2');
        logoText.id = "logoImage";
        logoText.style.fontWeight = "normal";
        logoText.style.fontSize = "17px";
        //logoText.style.webkitTextStroke = "1px #3b3b3b";
        logoText.style.color = "white";
        logoText.style.marginBottom = "0.5%";
        logoText.innerText = "Powered and Built by EmpireCreates.com";
        logoText.alt = 'Empire Logo';

        container.appendChild(div1);
        div1.appendChild(b1);
        div1.appendChild(document.createElement('br'));
        div1.appendChild(img1);

        container.appendChild(div2);
        div2.appendChild(b2);
        div2.appendChild(document.createElement('br'));
        div2.appendChild(img2);

        container.appendChild(div3);
        div3.appendChild(b3);
        div3.appendChild(document.createElement('br'));
        div3.appendChild(img3);

        setOriginalH(document.getElementById('tg').scrollHeight);

        map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(container);
        if(!isMobile)
        {
            map.controls[window.google.maps.ControlPosition.BOTTOM_LEFT].push(logoText);
        }
        
        }
        
    }

    useEffect(() => 
    {
        if (!map) return;//In the case where map doesn't load

        //Sets the controls onto the map when the view is visible. 
        if(appView === "flex" && setMapControlsInitialized)
        {
            setMapC(map);
            setMapControlsInitialized(false);
        }

        // Function to create a new polyline
        const createPolyline = () => 
        {
            const poly = new window.google.maps.Polyline
            ({
                clickable: false,
                map: map,
                strokeColor: strokeColors[selectedFence],
                strokeWeight: 6,
            });
            // Add click event listener to the polyline
            poly.addListener('click', (event) => 
            {
                // Get the coordinates where the user clicked
                if(gateSelectedRef.current)
                {
                    const clickedLocation = event.latLng;
                    addGate(clickedLocation, gateTypeRef.current);
                }else{
                    console.log("not enabled.");
                }
            });
            return poly;
        };

        const changePolylineClickable = (polyline, clickable) => {polyline.setOptions({ clickable: clickable });};

        // Function to draw polylines
        const drawFreeHand = () => 
        {
            let poly = createPolyline(); // Create a new polyline
            let path = []; // Array to store raw points
            let isDrawing = false; // Flag to track drawing state
            let mouseDownCount = 0;//Counter for times mouse down event is occuring

            mapAreaClickListener = function(event) 
            {
                setClickDrawn(true);
                if(mouseDownCount === 2 && mouseUpCounter === 2)
                {
                    handleToggleDrawingMode();
                    handleToggleDrawingMode();
                    mouseDownCount = 0;
                    mouseUpCounter = 0;
                }
            };
            document.getElementById("mapArea").addEventListener('click', mapAreaClickListener);
            if(isMobile)
            {
                document.getElementById("mapArea").addEventListener('touchend', mapAreaClickListener);    
            }

            mouseDownListener = window.google.maps.event.addListener(map, 'mousedown', (e) => 
            {
                mouseDownCount++;
                isDrawing = true; // Start drawing
                path = []; // Clear the previous path
                path.push(e.latLng); // Start new path
                poly.setPath(path); // Update the polyline's path
            });

            mouseMoveListener = window.google.maps.event.addListener(map, 'mousemove', (e) => 
            {
                if (isDrawing) {
                    path.push(e.latLng); // Store raw points as the mouse moves
                    poly.setPath(path); // Update the polyline's path
                }
            });

            mouseUpListener = window.google.maps.event.addListener(map, 'mouseup', (e) => 
            {
                mouseUpCounter++;
                if (isDrawing) {
                    isDrawing = false; // Stop drawing
                    if (path.length > 1) {
                        poly.setPath(smoothPath(path)); // Update the polyline's path with simplified points
                        changePolylineClickable(poly, true);
                        drawMapLines(poly);
                        poly = createPolyline(); // Create a new polyline for the next drawing
                        mouseUpCounter=0;
                        mouseDownCount=0;
                    }
                }
            });
        };

        if (drawingMode) 
        {
            //In case where we are drawing, disable polyline clickability. 
            polyArray.forEach(row => {
                row.forEach(polyline => {
                    if (polyline) {
                        //console.log("Disabled click");
                        polyline.setOptions({ clickable: false });
                    }
                });
            });
            drawFreeHand();
        }else{
            //In the case where we are not drawing, be able to click the polyline. 
            polyArray.forEach(row => {
                row.forEach(polyline => {
                    if (polyline) {
                        //console.log("Enabled click");
                        polyline.setOptions({ clickable: true });
                    }
                });
            });
        }

        return () => 
        {
            // Remove event listeners when the component unmounts
                //document.getElementById("mapArea").removeEventListener('click', mapAreaClickListener);
            window.google.maps.event.removeListener(mouseUpListener);
            window.google.maps.event.removeListener(mouseDownListener);
            window.google.maps.event.removeListener(mouseMoveListener);
        };
    }, [map, drawingMode, selectedFence, mapControlsInitialized, appView]);

    // Function to smooth the polyline drawing
    const smoothPath = (path) => 
    {
        const smoothedPath = [];
        let start = 0;
        for (let i = 1; i < path.length; i++) 
        {
            // Check if the current segment is a straight line
            const isStraight = isStraightSegment(path[start], path[i]);
            if (!isStraight) 
            {
                // If the segment is not straight, add it to the smoothed path
                smoothedPath.push(...simplify(path.slice(start, i), 0.0001, true));
                start = i;
            }
        }
        // Add the last segment to the smoothed path
        smoothedPath.push(...simplify(path.slice(start), 0.0001, true));
        return smoothedPath;
    };

    // Function to check if a segment is a straight line
    const isStraightSegment = (point1, point2) => {
        // Your logic to determine if the segment between point1 and point2 is straight
        // For example, you can calculate the slope and check if it's close to zero
        return Math.abs(point1.lat() - point2.lat()) < 0.0001 && Math.abs(point1.lng() - point2.lng()) < 0.0001;
    };

    // Handles Drawing of Polyline, Markers and Stores Footage.
    const drawMapLines = (poly) =>
    {
        // Calculate the distance between consecutive points in the path
        let length = 0; // Used to house polyline length
        const pathh = poly.getPath();
        for (let i = 0; i < pathh.getLength() - 1; i++) 
        {
            length += window.google.maps.geometry.spherical.computeDistanceBetween(pathh.getAt(i), pathh.getAt(i + 1));
        }
        length = length * 3.28084; // Convert meters to feet (1 meter = 3.28084 feet)

        //Finding the open space of the lines array, storing polyline into arr spot, generating marker.
        const firstOpenSpaceIndex = linesArray[selectedFence].indexOf(0); // Find the first open space in the lines array
        let letter = String.fromCharCode(firstOpenSpaceIndex + 65); // Determing the line letter, instead of numeric index. 1->a 2->b. 
        if (firstOpenSpaceIndex !== -1 && length > 0) 
        {
            // Get the map object from the DrawingManagerF component
            const mapObject = map;
            // Calculate the midpoint based on the bounding box
            const bounds = new window.google.maps.LatLngBounds();
            pathh.forEach((point) => bounds.extend(point));
            const midpoint = bounds.getCenter();
            // Create a marker and position it at the midpoint of the polyline
            const marker = new window.google.maps.Marker({
                position: midpoint,
                map: mapObject,
                icon: whiteSquare,
                label: selectedFence + letter,
                zIndex: 1,
                //opacity: 0.5,
            });

            //Wonder if I can remove these...Updating the Array without these. 
            const updatedArray = [...linesArray];
            const updatedArray2 = [...polyArray];
            const updatedArray3 = [...markerArray];
            updatedArray[selectedFence][firstOpenSpaceIndex] = Math.round(length);
            updatedArray2[selectedFence][firstOpenSpaceIndex] = poly;
            updatedArray3[selectedFence][firstOpenSpaceIndex] = marker;
            setLinesArray(updatedArray);
            setPolyArray(updatedArray2);
            setMarkerArray(updatedArray3);
            //Disable polyline clickability to reduce errors when drawing.
            polyArray.forEach(row => {
                row.forEach(polyline => {
                    if (polyline) {
                        polyline.setOptions({ clickable: false });
                    }
                });
            });

        }
    }

    // Handles Events When Polyline is Drawn
    const handleOverlayComplete = (e) => 
    {
        if (e.type === 'polyline' && clickDrawn) 
        {
          const poly = e.overlay;
          poly.addListener('click', (event) => 
          {
            // Get the coordinates where the user clicked
            if(gateSelectedRef.current)
            {
                const clickedLocation = event.latLng;
                addGate(clickedLocation, gateTypeRef.current);
            }else{
                console.log("not enabled.");
            }
          });
          poly.setOptions({ clickable: false });
          drawMapLines(poly);
        }
    };

    //Handles Toggling Drawing Mode
    const handleToggleDrawingMode = () => 
    {
      if (drawingManager) 
      {
        const newDrawingMode = drawingManager.getDrawingMode() ? null : 'polyline';// Gathers Opp. Mode
        drawingManager.setDrawingMode(newDrawingMode);// Sets opposite mode.
        if (newDrawingMode === null)
        {   // Disabling Drawing Mode: 
            setDrawingMode(false);
            setMapDrag(true);
        } else {
            // Enablind Drawing Mode: 
            setDrawingMode(true);
            setMapDrag(false);
        }
      }
    };

    // Function to enable drawing mode
    const enableDrawingMode = () => 
    {
        if (drawingManager) 
        {
          drawingManager.setDrawingMode('polyline');
          setDrawingMode(true);
          setMapDrag(false);
        }
      };

    // Function to disable drawing mode
    const disableDrawingMode = () => {
        if (drawingManager) 
        {
            drawingManager.setDrawingMode(null);
            setDrawingMode(false);
            setMapDrag(true);
        }
    };

    //Function for deleting a polyline. 
    const updateArrayValue = (rowIndex, columnIndex) => 
    {
        (polyArray[rowIndex][columnIndex]).setMap(null);  //Delete polyline.
        (markerArray[rowIndex][columnIndex]).setMap(null);//Delete Marker.
        //Copying linesArr & polylineArr
        let linesArrCopy = [...linesArray];
        let polyArrCopy = [...polyArray];
        let markerArrCopy = [...markerArray];
        //Adjusting for deleted polyline.
        linesArrCopy[rowIndex][columnIndex]  = 0;
        polyArrCopy[rowIndex][columnIndex]   = 0;
        markerArrCopy[rowIndex][columnIndex] = 0;
        //Updating original linesArr for changes.
        setLinesArray(linesArrCopy);
        setPolyArray(polyArrCopy);
        setMarkerArray(markerArrCopy);
    };
    //Updating single gate deletes. 
    const updateArrayValue2 = (rowIndex, columnIndex) => {
        //(gatesArray[rowIndex][columnIndex]).setMap(null);  //Delete Gate.
        (markerArray2[rowIndex][columnIndex]).setMap(null);//Delete Marker.
        //Copying linesArr & polylineArr
        let gateArrCopy = [...gatesArray];
        let markerArrCopy = [...markerArray2];
        //Adjusting for deleted polyline.
        gateArrCopy[rowIndex][columnIndex]   = 0;
        markerArrCopy[rowIndex][columnIndex] = 0;
        //Updating original linesArr for changes.
        setGatesArray(gateArrCopy);
        setMarkerArray2(markerArrCopy);
    };

    // Function for deleting a polyline. 
    const deleteRow = (rowIndex) => 
    {
        // Deleting polyline.
        for (let i = 0; i < polyArray[rowIndex].length; i++) 
        {
            if (polyArray[rowIndex][i]) {
                polyArray[rowIndex][i].setMap(null); // Delete polyline.
                markerArray[rowIndex][i].setMap(null); // Delete Marker.
            }
        }
        // Remove the entire row from arrays.
        let linesArrCopy = linesArray.filter((_, index) => index !== rowIndex);
        let polyArrCopy = polyArray.filter((_, index) => index !== rowIndex);
        let markerArrCopy = markerArray.filter((_, index) => index !== rowIndex);
        // Updating original arrays for changes.
        setLinesArray(linesArrCopy);
        setPolyArray(polyArrCopy);
        setMarkerArray(markerArrCopy);
        // Filter out the fence with the given ID from the fences array
        const updatedFences = fences.filter(fence => fence.id !== rowIndex);
        // Update the state with the filtered fences array
        setFences(updatedFences);
    };

//All of these are UI-Related. Move outside of this code. 
    //Function used to update UI Visual for Selected Fence
    const selectFence = (fenceID) => 
    {
        setSelectedFence(fenceID);
        for (let i = 0; i < 10; i++)//Ensure both backgrounds are set to white. 
        {
            if(document.getElementById("fence#" +i) !== null)
            {
                document.getElementById("fence#" +i).style.backgroundColor = "white";
            }
        }
        if(document.getElementById("fence#" + fenceID) !== null)
        {
            if(document.getElementById("fence#" +fenceID).style.backgroundColor === "white")
            {
                document.getElementById("fence#" +fenceID).style.backgroundColor = "#D3D3D3";
            }
        }
        
    }

    //Function to handle UI control selection. 
    function clickHandler(sel) 
    {
        //Setting all UI to defaults prior to updating. 
        document.getElementById("c1").style.backgroundColor = "white";
        document.getElementById("c2").style.backgroundColor = "white";
        document.getElementById("c3").style.backgroundColor = "white";
        document.getElementById("c1").style.color = "black";
        document.getElementById("c2").style.color = "black";
        document.getElementById("c3").style.color = "black";
        document.getElementById('c1img').setAttribute("src", blackCompass);
        document.getElementById('c2img').setAttribute("src", blackDrawing);
        document.getElementById('c3img').setAttribute("src", brownGate);
        setGateSelected(false);
        
        //Let's see..

        switch(sel)
        {
            case 1: //Case in which touring mode is enabled.
              //Updating UI to show touring control selected. 
              disableDrawingMode();//Regardlessly. 
              document.getElementById("c1").style.backgroundColor = "#620d0e";
              document.getElementById("c1").style.color = "white";
              document.getElementById('c1img').setAttribute("src", whiteCompass);
              break;

            case 2://Drawing Mode is Enabled.
              enableDrawingMode();
              document.getElementById("c2").style.backgroundColor = "#620d0e";
              document.getElementById("c2").style.color = "white";
              document.getElementById('c2img').setAttribute("src", whiteDrawing);
              break;

            case 3://Adding Gates Enabled. 
              disableDrawingMode();
              document.getElementById("c3").style.backgroundColor = "#620d0e";
              document.getElementById("c3").style.color = "white";
              document.getElementById('c3img').setAttribute("src", whiteGate);
              setGateSelected(true);
              setAppView("none");
              setGateSelectView("inline");
              break;

            default:
                break;

        }

    }

    function initDrawingManager(dm)
    {
        if(dm)
        {
            setDrawingManager(dm);
        }        
    }

    //Creating controls & adding to google maps tag. 
    function initMapControls(map)
    {
        setMap(map);
    }

    //UI Controlling app/site view
    function viewController()
    {
        setAppView('none');
        setEmpireSiteView('inline');
    }

    const [fences, setFences] = useState
    ([
        //{ id: 1, material: 'Vinyl', height: "4'", color: 'White', style: 'Picket', type: 'Victorian' },
    ]);

    const [fenceCount, setFenceCount] = useState(fences.length);
    const [showAddFenceBtn, setShowAddFenceBtn] = useState(true);

    function hideFenceSelect()
    {
        if(fenceCount == 2)
        {
            setShowAddFenceBtn(false);
        }
        setAppView('flex');
        setEmpireSiteView('none');
    }

    const addFence = (fenceAttributes) => 
    {
        const newFenceID = fenceCount + 1; // Generate a new ID for the new fence
        setFences([...fences, { id: newFenceID, ...fenceAttributes }]); // Add the new fence to the array
        setFenceCount(newFenceID);
        setAppView('flex');
        setEmpireSiteView('none');
        setOriginalH(document.getElementById('tg').scrollHeight);
        //This if statement selects the newly added marker to be instantly used for drawing
        if (fenceCount !== 0) {
            setTimeout(() => {
                selectFence(fenceCount + 1);
            }, 100);
        }        
    };
    
    return (
      <div className="App" id="tg" style={{overflow: 'none', height: isMobile? '88vh': '100vh'}}>
              <div id="targetElement" style={{ height: '100%', display: appView }}>
                <div style={{display: "flex", width: "100%", flexDirection: isMobile ? 'column' : 'row'}}>
                    <div style={{ flexGrow: 7, flexBasis: isMobile? '50%':'70%',}}>
                        <div style={{display: isMobile? "none" : "block", height: '60px', paddingTop: '5px', paddingBottom: '5px',textAlign: 'center', backgroundColor: '#620d0e'}}>
                            <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '60px'}} src="https://empirefences.com/wp-content/uploads/2024/07/empireCreatesLogoWhite2.png" alt="Company Logo" />
                        </div>
                        <GoogleMap 
                            id="mapArea"
                            mapContainerStyle={{height: '86%', width: "100%", }}
                            center={center}
                            gestureHandling={'none'}
                            zoom={isMobile? 19: 20} 
                            onLoad={(map) => initMapControls(map)}
                            options={{
                                gestureHandling: 'greedy',
                                mapTypeControl: false,
                                fullscreenControl: false,
                                streetViewControl: false,
                                mapTypeId: "satellite",
                                draggable: mapDrag
                            }}
                        >
                            <DrawingManagerF
                                options={{
                                    drawingControl: false,
                                    drawingControlOptions: {position: window.google.maps.ControlPosition.TOP_CENTER },
                                    polylineOptions: {
                                        strokeColor: strokeColors[selectedFence],
                                        strokeWeight: 6,
                                    },
                                }}
                                onLoad={(drawingManager) => initDrawingManager(drawingManager)}
                                onOverlayComplete={handleOverlayComplete}
                            />
                        </GoogleMap>
                    </div>

                    <div id="sideBar" style={{flexGrow: 3, flexBasis: isMobile? '50%':'30%', border: isMobile? '0px solid black' : '5px solid black', borderBottom: '0px', marginTop: isMobile? '-15%': '0', overflow: 'scroll' }}>
                        
                    
                        <div style={{textAlign: 'center', marginTop: '-3%'}}>
                            <h1>Project Details</h1>
                            {fenceCount > 1 && (
                                <h5 style={{ marginTop: '-4%', paddingTop: '0' }}>
                                    (To change drawing color, click a fence below)
                                </h5>
                            )}
                        </div>

                        <div id="fenceComponents" style={{display: 'flex', flexDirection: isMobile? 'row' : 'column', justifyContent: 'center', gap: isMobile? '1%' : '0' }}>
                            {fences.map(fence => {
                                const FenceComponent = isMobile ? MobileFenceComponent : CustomFenceComponent; // Conditional component
                                return (
                                <FenceComponent
                                    key={fence.id} // Assigning a unique key prop based on the fence id
                                    material={fence.material}
                                    height={fence.height}
                                    color={fence.color}
                                    style={fence.style}
                                    type={fence.type}
                                    lines={linesArray[fence.id]}
                                    gates={gatesArray[fence.id]}
                                    fenceID={fence.id}
                                    updateFenceId={selectFence}
                                    updateArrayValue={updateArrayValue}
                                    updateArrayValue2={updateArrayValue2}
                                    deleteRow={deleteRow}
                                />
                                );
                            })}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', paddingTop: '30px'}}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '40px', marginTop: isMobile? "-5%":'-7%'}}>
                                <div style={{display: showAddFenceBtn? 'flex': 'none'}}>
                                    <button className={styles.Button} onClick={viewController}>Add Fence</button>  
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <button
                                        onClick={captureAndCreateImage}
                                        id="submitB"
                                        className={`${styles.button} ${isLoading ? styles.loader : ''} ${isSuccess ? styles.success : ''} ${isError ? styles.error : ''}`}
                                        >
                                        {isLoading ? '' : isSuccess ? 'Success' : isError ? 'Error' : 'Submit'}
                                    </button>
                                </div>
                                     
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div style={{display: empireSiteView}}>
            <FenceSelect passData={addFence} fenceCount={fenceCount} returnFunction={hideFenceSelect}/>
        </div>
        <div style={{display: gateSelectView}}>
            <h1 style={{color: 'maroon'}}>Select Gate Type</h1>
            <div style={{display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column'}}>
                <div style={{textAlign: 'center', marginTop: '-2%'}} onClick={() => {hideGateSelect("S");}}>
                    <h1>Single</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '150px', height: '150px'}} src={"https://empirefences.com/wp-content/uploads/2025/01/singleGate-2.webp"} alt="Company Logo" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => {hideGateSelect("D"); setGateType("D")}}>
                    <h1>Double</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '200px', height: '150px'}} src={"https://empirefences.com/wp-content/uploads/2025/01/doubleGate-2.webp"} alt="Company Logo" />
                </div>
            </div>
        </div>
      </div>
  );
}

export default MapPage;
