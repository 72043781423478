import React from 'react';
import deleteIcon from "../images/deleteIcon.png";

/*
Component to easily display selected fence
->Handles deleteing objects from map as well via props functions.
*/

const MobileFenceComponent = ({ material, height, color, style, type, lines, gates, fenceID, updateFenceId, updateArrayValue, updateArrayValue2}) => 
{

  const handleDeleteIconClick = (fenceID, index, polyline) => 
  {// Call the passed updateArrayValue function with appropriate arguments
    updateArrayValue(fenceID, index, polyline);
  };
  const handleDeleteIconClick2 = (fenceID, index, polyline) => 
  {// Call the passed updateArrayValue function with appropriate arguments
    updateArrayValue2(fenceID, index, polyline);
  };

  const totalFootage = lines.reduce((acc, line) => acc + line, 0);

  //Line Stroke Colors) -, Gold, Cyan, Tomato, Violet-Blue, Lime, Orange-Red, Pink, Violet.
  const strokeColors = ['', '#CCAC00', '#3457C4', '#FF6347', '#8A2BE2', '#32CD32', '#FF4500', '#FF69B4', '#228B22', '#9400D3',];
  
  return (
  <div id={"fence#"+fenceID} onClick={() => updateFenceId(fenceID)} style ={{width:'115px', border: '3px solid black', marginBottom: '3%', backgroundColor: fenceID === 1 ? 'lightgray' : 'white'}}>
    <div style={{borderBottom: '3px solid black', backgroundColor: strokeColors[fenceID % strokeColors.length], color: 'white'}}>
      <div style={{height: '60px'}}>
        <h6 style={{ marginTop: '0', marginBottom: '2%'}}>{height} {color} {material} {style} {type}</h6>
        <h5 style={{paddingBottom: '0px', marginBottom: '2%', marginTop: '0%'}}><b>Total Ft: </b> {totalFootage}'</h5>
      </div>
    </div>
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div style={{ flexBasis: '50%', borderRight: '3px solid black'}}>
        <h6 style={{ marginBottom: '0px', marginTop: '0px'}}>Lines</h6>
        
         <ul style={{ marginTop: '0px', textAlign: 'left', marginBottom: '0px', paddingLeft: '0px', listStyle: 'none' }}>
          {lines.map((line, index) => (
            line !== 0 && (
              <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
                 <img
                  alt="deleteIcon"
                  onClick={() => handleDeleteIconClick(fenceID, index)}
                  src={deleteIcon}
                  style={{ horizontalAlign: 'left', verticalAlign: 'left', height: '20%', width: '20%' }}
                />
                <strong>
                  <h6 style={{ margin: '0px', marginLeft: '2px', fontSize: '10px' }}>{`${fenceID + String.fromCharCode(index + 65)}:`}</h6>
                </strong>
                <h6 style={{ margin: '0px', marginLeft: '2px', fontSize: '10px' }}>{line}'</h6>
              </li>
            )
          ))}
        </ul>
      </div>
      <div style={{flexBasis: '50%'}}>
        <h6 style={{ marginBottom: '0px', marginTop: '0'}}>Gates</h6>
         <ul style={{ marginTop: '0px', textAlign: 'left', marginBottom: '0px', paddingLeft: '0px', listStyle: 'none' }}>
         {gates.map((gate, index) => (
            gate !== 0 && (
              <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
                 <img
                  alt="deleteIcon2"
                  onClick={() => handleDeleteIconClick2(fenceID, index)}
                  src={deleteIcon}
                  style={{ horizontalAlign: 'left', verticalAlign: 'left', height: '20%', width: '20%' }}
                />
                  <h6 style={{ margin: '0px', marginLeft: '2px', fontSize: '10px' }}><strong>{`${'G' + (index+1)}:`}</strong></h6>
                  <h6 style={{ margin: '0px', marginLeft: '2px', fontSize: '10px' }}>{gate}</h6>
              </li>
            )
          ))}
        </ul>
      </div>
    </div>
  </div>
  );
};

export default MobileFenceComponent;
