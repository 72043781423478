import React from 'react';
import { Debounce } from '../components/debounce.js';
/*
Places-Page View; Takes in clients address and uses debounce page to 
properly come up google search suggestions making it easier for address selection.
*/
const PlacesPage = ({ passData }) => 
{
  return (
    <div style={{backgroundImage: "url('https://empirefences.com/wp-content/uploads/2024/12/LoginPage.jpg')", backgroundSize: 'cover',  backgroundPosition: 'center', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{textAlign: "center", height: '100vh' }}>
        <img style={{ padding: "0px 0px 20px 0px", maxWidth: "350px", width: "100%", marginTop: '40px'}} src="https://empirefences.com/wp-content/uploads/2024/07/empireCreatesLogoWhite2.png" alt="Company Logo" />
        <div style={{ textAlign: "center", color: "#fff" }}>
          <h2>Type Your Address Below</h2>
          <Debounce passData={passData}/>
        </div>
      </div>
    </div>
  );
}

export default PlacesPage;
